import React from 'react';
import { CircleToBlockLoading } from 'react-loadingg';
import theme from '../../utils/theme';

const Loading = () => (
  <div className="text-center relative mt-20">
    <CircleToBlockLoading color={theme.primary} />
  </div>
);
export default Loading;
