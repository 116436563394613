import PropTypes from 'prop-types';

const TableData = ({ children, className, align, ...props }) => (
  <td
    className={`px-6 py-3 whitespace-nowrap text-${align} border text-sm text-black ${className}`}
    {...props}
  >
    {children}
  </td>
);

TableData.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  align: PropTypes.oneOf(['center', 'left', 'right']),
};

TableData.defaultProps = {
  children: '',
  className: '',
  align: 'center',
};

export default TableData;
