import PropTypes from 'prop-types';

const TableHeading = ({ children, className, align, ...props }) => (
  <th
    scope="col"
    className={`px-6 py-4 text-${
      align || 'center'
    } text-xs font-bold bg-backgroundLite uppercase tracking-wider text-black border ${className}`}
    {...props}
  >
    {children}
  </th>
);

TableHeading.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  align: PropTypes.oneOf(['center', 'left', 'right']),
};

TableHeading.defaultProps = {
  className: '',
  align: 'center',
};

export default TableHeading;
