import PropTypes from 'prop-types';

const Navbar = ({ children, className, ...props }) => {
  return (
    <nav className={`${className}`} {...props}>
      {children}
    </nav>
  );
};

Navbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Navbar.defaultProps = {
  className: '',
};

export default Navbar;
