import React from 'react';

const Heading = ({ type, children, className, ...props }) => {
  const classNameMerged = `font-medium ${className}`;

  if (type === 'h1') {
    return (
      <h1 className={classNameMerged} {...props}>
        {children}
      </h1>
    );
  }
  if (type === 'h2') {
    return (
      <h2 className={classNameMerged} {...props}>
        {children}
      </h2>
    );
  }
  if (type === 'h3') {
    return (
      <h3 className={classNameMerged} {...props}>
        {children}
      </h3>
    );
  }
  if (type === 'h5') {
    return (
      <h5 className={classNameMerged} {...props}>
        {children}
      </h5>
    );
  }
  if (type === 'h6') {
    return (
      <h6 className={classNameMerged} {...props}>
        {children}
      </h6>
    );
  }

  return (
    <h4 className={classNameMerged} {...props}>
      {children}
    </h4>
  );
};

export default Heading;
