const Label = ({ children, className, textColor, ...props }) => (
  <label
    className={`leading-6 md:leading-10 font-medium text-sm ${
      textColor || 'text-gray'
    } mb-3 ${className}`}
    {...props}
  >
    {children}
  </label>
);

export default Label;
