const Input = ({
  className,
  borderRadius,
  hasNotBorder,
  bgColor,
  borderColor,
  ...props
}) => (
  <input
    style={{ borderColor: borderColor || undefined }}
    className={`w-full h-auto bg-${bgColor || 'white'} ${
      borderRadius || 'rounded-2xl'
    } border ${
      hasNotBorder || 'border-primary'
    } focus:border-primary focus:bg-white text-base outline-none text-gray py-5 px-8 resize-none leading-6 transition-colors duration-200 ease-in-out ${className}`}
    {...props}
  />
);

export default Input;
