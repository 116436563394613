import React, { useState, useEffect } from 'react';

const TextArea = ({
  className,
  borderRadius,
  hasNotBorder,
  onChange,
  onBlur,
  name,
  ...props
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
    return () => {
      setValue('');
    };
  }, [props.value]);

  return (
    <textarea
      name={name}
      rows={6}
      className={`w-full bg-white ${borderRadius || 'rounded-2xl'} border ${
        hasNotBorder || 'border-primary'
      } focus:border-primary focus:bg-white focus:border-primary text-base outline-none text-gray py-5 px-8 resize-none leading-6 transition-colors duration-200 ease-in-out ${className}`}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={(e) => {
        if (onChange) {
          onChange(e);
        }
        if (onBlur) {
          onBlur(e);
        }
      }}
      // {...props}
    />
  );
};

export default TextArea;
