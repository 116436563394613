import PropTypes from 'prop-types';

const Span = ({ children, className, size, fontWeight, ...props }) => (
  <span
    className={`leading-7 text-${size} font-${fontWeight} ${className}`}
    {...props}
  >
    {children}
  </span>
);

Span.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['base', 'xs', 'sm', '3xl', 'lg']),
  fontWeight: PropTypes.oneOf([
    'normal',
    'thin',
    'medium',
    'semibold',
    'bold',
    'extralight',
  ]),
};

Span.defaultProps = {
  className: '',
  size: 'sm',
  fontWeight: 'normal',
};

export default Span;
