import PropTypes from 'prop-types';

const Paragraph = ({ children, className, size, fontWeight, ...props }) => (
  <p
    className={`leading-6 text-${size} font-${fontWeight} ${className}`}
    {...props}
  >
    {children}
  </p>
);

Paragraph.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl']),
  fontWeight: PropTypes.oneOf(['normal', 'thin', 'medium', 'semibold', 'bold']),
};

Paragraph.defaultProps = {
  children: '',
  className: '',
  size: 'sm',
  fontWeight: 'normal',
};

export default Paragraph;
